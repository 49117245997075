// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-additions-and-renovations-js": () => import("./../../../src/pages/services/additions_and_renovations.js" /* webpackChunkName: "component---src-pages-services-additions-and-renovations-js" */),
  "component---src-pages-services-and-more-js": () => import("./../../../src/pages/services/and_more.js" /* webpackChunkName: "component---src-pages-services-and-more-js" */),
  "component---src-pages-services-basements-js": () => import("./../../../src/pages/services/basements.js" /* webpackChunkName: "component---src-pages-services-basements-js" */),
  "component---src-pages-services-bathrooms-js": () => import("./../../../src/pages/services/bathrooms.js" /* webpackChunkName: "component---src-pages-services-bathrooms-js" */),
  "component---src-pages-services-decks-and-fences-js": () => import("./../../../src/pages/services/decks_and_fences.js" /* webpackChunkName: "component---src-pages-services-decks-and-fences-js" */),
  "component---src-pages-services-flooring-js": () => import("./../../../src/pages/services/flooring.js" /* webpackChunkName: "component---src-pages-services-flooring-js" */),
  "component---src-pages-services-framing-and-drywall-js": () => import("./../../../src/pages/services/framing_and_drywall.js" /* webpackChunkName: "component---src-pages-services-framing-and-drywall-js" */),
  "component---src-pages-services-kitchens-js": () => import("./../../../src/pages/services/kitchens.js" /* webpackChunkName: "component---src-pages-services-kitchens-js" */),
  "component---src-pages-services-painting-js": () => import("./../../../src/pages/services/painting.js" /* webpackChunkName: "component---src-pages-services-painting-js" */),
  "component---src-pages-services-roofing-siding-gutters-js": () => import("./../../../src/pages/services/roofing_siding_gutters.js" /* webpackChunkName: "component---src-pages-services-roofing-siding-gutters-js" */),
  "component---src-pages-services-stonework-and-concrete-js": () => import("./../../../src/pages/services/stonework_and_concrete.js" /* webpackChunkName: "component---src-pages-services-stonework-and-concrete-js" */),
  "component---src-pages-services-windows-and-doors-js": () => import("./../../../src/pages/services/windows_and_doors.js" /* webpackChunkName: "component---src-pages-services-windows-and-doors-js" */)
}

